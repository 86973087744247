<template>
  <div v-resize="handleResize">
    <canvas
        ref="canvas"
        class="linear-chart"
        @mousedown="handleMouseDown"
        @mouseup="handleMouseUp"
        @mouseleave="handleMouseLeave"
        @mousemove="handleMouseMove"
    >
    </canvas>
  </div>
</template>

<script>


import {ControlPoint, getAutoChartGrid, Point, SerialMetadata} from "@/components/chart/SerialMetadata";

export default {
  name: "LinearChart",
  props: {
    serials: {
      type: Array,
      default() {
        return [];
      }
    },
    regions: {
      type: Array,
      default() {
        return [];
      }
    },
    type: {
      type: String,
      default: "line",
    },
    actived: {
      type: String,
      value: null,
    },
    steps: {
      type: Number,
      default: 7,
    },
  },
  data() {
    return {
      /**
       * 绘图上下文
       */
      ctx: null,

      /**
       * 绘图区大小
       */
      width: 0,
      height: 0,

      /**
       * 判断是否需要刷新，一个tick周期只允许刷新一次。通过render方法控制
       */
      requireRender: false,

      /**
       * 原始index位置，原始index位置的x坐标=this.offset
       */
      originalIndex: 0,

      /**
       * 偏移量
       */
      offset: 0,
      maxOffset: null,
      minOffset: null,

      /**
       * 数据序列元数据
       */
      serialMetadataMap: {},

      /**
       * 坐标网格
       */
      grid: [],

      /**
       * 动画进度（0.0-1.0）
       */
      fraction: 1.0,

      /**
       * index范围
       */
      minIndex: null,
      maxIndex: null,

      /**
       * 最小值/最大值范围
       */
      minVal: null,
      maxVal: null,

      /**
       * 边距
       */
      paddingTop: 32,
      paddingBottom: 20,
      paddingLeft: 2,
      paddingRight: 2,

      /**
       * 动画
       */
      animationDuration: 1000,
      animationInterval: 20,

      fractionTimerId: null,

      scrollTimerId: null,

      flyingTimerId: null,

      /**
       * 鼠标事件
       */
      mouseStartX: 0,
      mouseStartOffset: 0,
      previousMouseX: null,
      previousMouseTime: null,
      isMouseStarting: false,
      isMouseMoving: false,
      flyingSpeed: null,

      hoverIndex: null,
      centerIndex: null,


      /**
       * 显示便签
       */
      isTipVisible: false,
      tipTimerId: null,
      tipIndex: null,
    }
  },

  watch: {
    serials: {
      handler: function () {

        let isFirstRender = true;
        for (let serialKey in this.serialMetadataMap) {
          for (let serial of this.serials) {
            if (serial.key === serialKey) {
              isFirstRender = false;
              break;
            }
          }
          if (!isFirstRender) break;
        }
        if (isFirstRender) {
          this.serialMetadataMap = {};
        }
        this.updateSerialMetadata();
        if (isFirstRender && this.maxIndex) {
          this.scrollToIndex(this.maxIndex, false);
        }
      },
      immediate: true,
    }
  },

  mounted() {
    this.render();
  },

  methods: {

    handleResize: function () {
      if (this.scrollTimerId) {
        clearInterval(this.scrollTimerId);
        this.scrollTimerId = null;
      }
      if (this.flyingTimerId) {
        clearInterval(this.flyingTimerId);
        this.flyingTimerId = null;
      }
      if (this.fractionTimerId) {
        clearInterval(this.fractionTimerId);
        this.fractionTimerId = null;
      }
      this.ctx = null;
      this.serialMetadataMap = {};

      this.initContext(false);
    },

    handleMouseDown: function (e) {
      this.mouseStartX = e.x;
      this.mouseStartOffset = this.offset;
      this.isMouseStarting = true;
      this.previousMouseTime = null;
      this.previousMouseX = null;
      this.flyingSpeed = null;

      if (this.scrollTimerId) {
        clearInterval(this.scrollTimerId);
        this.scrollTimerId = null;
      }
      if (this.flyingTimerId) {
        clearInterval(this.flyingTimerId);
        this.flyingTimerId = null;
      }
    },

    handleMouseUp: function (e) {
      if (this.isMouseStarting) {
        let rect = this.$refs.canvas.getBoundingClientRect();
        let clickedIndex = this.getIndexByX(e.x - rect.left);
        this.scrollToIndex(clickedIndex);
      } else {
        this.startFlying();
      }

      this.isMouseStarting = false;
      this.isMouseMoving = false;


    },

    handleMouseLeave: function () {
      this.isMouseStarting = false;
      this.isMouseMoving = false;
      this.hideTip();
    },

    handleMouseMove: function (e) {
      if (this.isMouseStarting) {
        this.isMouseStarting = false;
        this.isMouseMoving = true;
      } else if (this.isMouseMoving) {
        let offset = this.mouseStartOffset + (e.x - this.mouseStartX);
        let time = new Date().getTime();
        if (this.previousMouseTime == null || time - this.previousMouseTime > 20) {
          if (this.previousMouseTime != null) {
            let flyingSpeed = (e.x - this.previousMouseX) / (time - this.previousMouseTime) * .5;
            this.flyingSpeed = flyingSpeed;
          }
          this.previousMouseTime = time;
          this.previousMouseX = e.x;
        }
        this.setOffset(offset, false);
        this.getCenterIndex();
      } else {
        let rect = this.$refs.canvas.getBoundingClientRect();
        let hoverIndex = this.getIndexByX(e.x - rect.left);
        this.showTip(hoverIndex);
      }
    },

    showTip: function (index) {
      this.hideTip();
      this.tipIndex = index;

      this.tipTimerId = setTimeout(() => {
        this.isTipVisible = true;
        this.render();
        clearTimeout(this.tipTimerId);
        this.tipTimerId = null;
      }, 200);
    },

    hideTip: function () {
      if (this.tipTimerId) {
        clearTimeout(this.tipTimerId);
        this.tipTimerId = null;
      }
      this.isTipVisible = false;
      this.render();
    },

    getCenterIndex() {
      let chartWidth = this.width - this.paddingLeft - this.paddingRight;
      return this.getIndexByX(.5 * chartWidth + this.paddingLeft);
    },

    getIndexByX(x) {
      let chartWidth = this.width - this.paddingLeft - this.paddingRight;
      let xInterval = chartWidth / this.steps;
      let index = Math.round((x - this.paddingLeft - this.offset) / xInterval);
      return index;
    },

    getCenterOffsetByIndex(index) {
      let chartWidth = this.width - this.paddingLeft - this.paddingRight;
      let xInterval = chartWidth / this.steps;
      return -(index * xInterval - .5 * chartWidth);
    },

    startFlying: function () {
      if (this.flyingTimerId) {
        clearInterval(this.flyingTimerId);
        this.flyingTimerId = null;
      }

      if (!this.flyingSpeed) {
        this.startPaging();
      } else {
        let flyingRatio = .98;
        this.flyingTimerId = setInterval(() => {
          let offsetDelta = Math.round(this.flyingSpeed * this.animationInterval);
          let isFinished = false;
          let centerIndex = this.getCenterIndex();
          let pageOffset = this.getCenterOffsetByIndex(centerIndex);
          let steps = this.animationDuration / this.animationInterval;
          let pageOffsetDelta = (pageOffset - this.offset) / steps;
          if (Math.abs(pageOffsetDelta) > Math.abs(offsetDelta)) {
            isFinished = true;
          }

          if (offsetDelta === 0) {
            isFinished = true;
          }

          let offset = this.offset + offsetDelta;
          this.setOffset(offset, false);
          if (isFinished) {
            clearInterval(this.flyingTimerId);
            this.flyingTimerId = null;
            this.startPaging();
          }
          this.flyingSpeed = this.flyingSpeed * flyingRatio;
        }, 0, this.animationInterval)
      }
    },

    startPaging: function () {
      let centerIndex = this.getCenterIndex();
      this.scrollToIndex(centerIndex);
    },

    scrollToIndex: function (index, animated = true) {
      let offset = this.getCenterOffsetByIndex(index);
      this.setOffset(offset, animated);
    },

    setOffset: function (targetOffset, animated = true) {
      if (this.scrollTimerId) {
        clearInterval(this.scrollTimerId);
        this.scrollTimerId = null;
      }

      if (this.minOffset && targetOffset < this.minOffset) {
        targetOffset = this.minOffset;
      }
      if (this.maxOffset && targetOffset > this.maxOffset) {
        targetOffset = this.maxOffset;
      }

      if (animated) {
        let steps = this.animationDuration / this.animationInterval;
        let offsetDelta = (targetOffset - this.offset) / steps;

        this.scrollTimerId = setInterval(() => {
          let offset = this.offset + offsetDelta;
          let isFinished = false;
          if (Math.abs(targetOffset - offset) <= Math.abs(offsetDelta)) {
            isFinished = true;
            offset = targetOffset;
          }
          this.checkEdgeTouch(offset, this.offset);
          this.offset = offset;
          this.centerIndex = this.getCenterIndex();
          if (isFinished) {
            clearInterval(this.scrollTimerId);
            this.scrollTimerId = null;
          }
          this.render();
        }, 0, this.animationInterval);
      } else {
        this.checkEdgeTouch(targetOffset, this.offset);
        this.offset = targetOffset;
        this.centerIndex = this.getCenterIndex();
        this.render();
      }
    },

    checkEdgeTouch: function (offset, oldOffset) {
      let thresholdRight = this.minOffset + this.transformIndex2X(.5 * this.steps);
      let thresholdLeft = this.maxOffset - this.transformIndex2X(.5 * this.steps);

      if (oldOffset >= thresholdRight && offset < thresholdRight) {
        this.$emit('scrolltoright');
      }
      if (oldOffset <= thresholdLeft && offset > thresholdLeft) {
        this.$emit('scrolltoleft');
      }
    },

    initContext: function (animated = true) {
      if (this.ctx) return;
      let centerIndex = this.getCenterIndex();

      let scale = 4;
      let canvas = this.$refs.canvas;
      let clientRect = canvas.getBoundingClientRect();
      let width = Math.floor(clientRect.width);
      let height = Math.floor(clientRect.height);
      this.width = width;
      this.height = height;
      canvas.width = this.width * scale;
      canvas.height = this.height * scale;
      let ctx = canvas.getContext("2d");
      ctx.scale(scale, scale);
      this.ctx = ctx;
      this.serialMetadataMap = {};
      this.updateSerialMetadata(animated);
      if (centerIndex != null) {
        this.scrollToIndex(centerIndex, false);
      }
    },

    render: function () {
      if (this.requireRender) return;
      this.requireRender = true;
      this.$nextTick(() => {
        this.initContext();
        this.requireRender = false;
        this.drawDirectly(this.ctx);
      })
    },

    /**
     * 坐标转换x
     * @param index
     */
    transformIndex2X(index) {
      return (this.width - this.paddingLeft - this.paddingRight) * index / this.steps + this.paddingLeft;
    },

    /**
     * 坐标转换y
     * @param val
     */
    transformVal2Y(val) {
      return this.height - this.paddingBottom - (this.height - this.paddingTop - this.paddingBottom) * (val - this.minVal) / (this.maxVal - this.minVal);
    },

    /**
     * 获取已定义的序列元数据，或者返回空
     */
    getSerialMetadataMap: function (serial) {
      return this.serialMetadataMap[serial.key];
    },

    updateSerialMetadata: function (animated = true) {
      if (this.fractionTimerId) {
        clearInterval(this.fractionTimerId);
        this.fractionTimerId = null;
      }

      let serialMetadataMap = {};
      let metadataList = [];

      let minVal = null;
      let maxVal = null;
      let minIndex = null;
      let maxIndex = null;

      for (let serial of this.serials) {
        let serialKey = serial.key;
        let oldSerialMetadata = this.serialMetadataMap[serialKey];
        let serialMetadata = new SerialMetadata(serial, oldSerialMetadata);
        if (serialMetadata.minIndex == null) continue;

        metadataList.push(serialMetadata);

        if (minVal == null || minVal > serialMetadata.minVal) {
          minVal = serialMetadata.minVal;
        }
        if (maxVal == null || maxVal < serialMetadata.maxVal) {
          maxVal = serialMetadata.maxVal;
        }
        if (minIndex == null || minIndex > serialMetadata.minIndex) {
          minIndex = serialMetadata.minIndex;
        }
        if (maxIndex == null || maxIndex < serialMetadata.maxIndex) {
          maxIndex = serialMetadata.maxIndex;
        }
      }

      if (minVal != null && maxVal != null) {
        let grid = getAutoChartGrid(minVal, maxVal);
        let gridStep = grid.length > 1 ? grid[1].val - grid[0].val : 0;
        this.minVal = grid[0].val - .1 * gridStep;
        this.maxVal = grid[grid.length - 1].val + .1 * gridStep;
        this.minIndex = minIndex;
        this.maxIndex = maxIndex;
        this.grid = grid;
      }

      for (let serialMetadata of metadataList) {
        let oldSerialMetadata = this.serialMetadataMap[serialMetadata.key];
        for (let value of serialMetadata.values) {
          if (value.isEmpty) continue;
          let key = value.key;
          let index = serialMetadata.converter.convertKey2Index(key);

          let oldControlPoint = oldSerialMetadata?.getControlPointByKey(key);
          let source;
          let target;
          if (oldControlPoint) {
            source = oldControlPoint.getCurrentPoint(this.fraction);
          } else {
            source = new Point(
                this.transformIndex2X(index),
                this.transformVal2Y(minVal)
            );
          }
          target = new Point(
              this.transformIndex2X(index),
              this.transformVal2Y(value.val),
          )
          let controlPoint = new ControlPoint(
              value,
              source,
              target,
          )
          serialMetadata.controlPoints.push(controlPoint);
        }
        serialMetadataMap[serialMetadata.key] = serialMetadata;
      }

      this.serialMetadataMap = serialMetadataMap;

      let maxOffset = null;
      let minOffset = null;
      if (this.minIndex != null) {
        maxOffset = this.getCenterOffsetByIndex(this.minIndex);
      }
      if (this.maxIndex != null) {
        minOffset = this.getCenterOffsetByIndex(this.maxIndex);
      }
      this.maxOffset = maxOffset;
      this.minOffset = minOffset;
      if (this.offset < this.minOffset) {
        this.setOffset(this.minOffset);
      } else if (this.offset > this.maxOffset) {
        this.setOffset(this.maxOffset);
      }

      if (animated) {
        this.fraction = 0;
        this.render();
        let steps = this.animationDuration / this.animationInterval;
        let fractionDelta = 1 / steps;
        this.fractionTimerId = setInterval(() => {
          let fraction = this.fraction += fractionDelta;
          let isFinished = false;
          if (Math.abs(fraction - 1) <= Math.abs(fractionDelta)) {
            isFinished = true;
            fraction = 1;
          }
          this.fraction = fraction;
          this.render();
          if (isFinished) {
            clearInterval(this.fractionTimerId);
            this.fractionTimerId = null;
          }
        }, 0, this.animationInterval);
      } else {
        this.fraction = 1.0;
        this.render();
      }


    },

    drawDirectly: function (ctx) {
      ctx.clearRect(0, 0, this.width, this.height);

      this.drawRegion(ctx);
      this.drawGrid(ctx);
      ctx.save();
      ctx.beginPath();
      ctx.rect(this.paddingLeft, 0, this.width - this.paddingLeft - this.paddingRight, this.height);
      ctx.clip();
      ctx.closePath();

      switch (this.type) {
        case "line":
          this.drawCurve(ctx);
          break;
        case "histogram":
          this.drawHistogram(ctx);
          break;
      }

      this.drawTip(ctx);

      ctx.restore();
    },

    drawRegion: function (ctx) {
      let converter = null;
      for (let serialKey in this.serialMetadataMap) {
        converter = this.serialMetadataMap[serialKey].converter;
        if (converter) break;
      }
      if (!converter) return;
      for (let region of this.regions) {
        ctx.save();
        ctx.fillStyle = region.color;
        let xList = new Array(region.values.length);
        for (let n = 0; n < region.values.length; n++) {
          let value = region.values[n];
          let index = converter.convertKey2Index(value.key);
          let x = this.transformIndex2X(index) + this.offset;
          let y = typeof value.lower === 'undefined' || value.lower == null ? this.height - this.paddingBottom : this.transformVal2Y(value.lower);
          if (n == 0) {
            ctx.moveTo(x, y);
          } else {
            ctx.lineTo(x, y);
          }
          xList[n] = x;
        }
        for (let n = region.values.length - 1; n >= 0; n--) {
          let value = region.values[n];
          let x = xList[n];
          let y = typeof value.upper === 'undefined' || value.upper == null ? this.paddingTop : this.transformVal2Y(value.upper);
          ctx.lineTo(x, y);
        }
        ctx.fill();
        ctx.closePath();
        ctx.restore();
      }
    },

    drawGrid: function (ctx) {
      let fontSize = 12;
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = "#dadada";
      ctx.fillStyle = "#bec0c0";
      ctx.font = `${fontSize}pt Arial`;
      ctx.textAlign = "left";

      ctx.moveTo(this.paddingLeft, this.paddingTop);
      ctx.lineTo(this.paddingLeft, this.height - this.paddingBottom);
      ctx.lineTo(this.width - this.paddingRight, this.height - this.paddingBottom);
      ctx.lineTo(this.width - this.paddingRight, this.paddingTop);

      for (let gridItem of this.grid) {
        let y = this.transformVal2Y(gridItem.val);
        ctx.moveTo(this.paddingLeft, y);
        ctx.lineTo(this.width - this.paddingRight, y);
        ctx.fillText(gridItem.label, this.paddingLeft + 5, y);
      }
      ctx.stroke();
      ctx.closePath();

      ctx.beginPath();
      let cx = (this.width - this.paddingLeft - this.paddingRight) * .5 + this.paddingLeft;
      ctx.moveTo(cx, this.paddingTop);
      ctx.lineTo(cx, this.height - this.paddingBottom);
      ctx.stroke();
      ctx.closePath();

      let converter = null;
      for (let serialKey in this.serialMetadataMap) {
        converter = this.serialMetadataMap[serialKey]?.converter;
        if (converter) break;
      }
      if (converter && this.minIndex != null && this.maxIndex != null) {
        ctx.beginPath();
        let labelMinIndex = this.getIndexByX(0);
        let labelMaxIndex = this.getIndexByX(this.width);

        ctx.textAlign = "center";

        for (let index = labelMinIndex; index <= labelMaxIndex; index++) {
          let key = converter.convertIndex2Key(index);
          let label = converter.formatLabel(key, index);
          if (label) {
            ctx.fillText(label, this.transformIndex2X(index) + this.offset, this.height - this.paddingBottom + fontSize + 4);
          }
        }
        ctx.closePath();
      }

    },

    isPeek: function (point, prePoint, postPoint) {
      if (point.y <= prePoint.y && point.y <= postPoint.y) return true;
      if (point.y >= prePoint.y && point.y >= postPoint.y) return true;
      return false;
    },

    drawCurve: function (ctx) {
      for (let serialKey in this.serialMetadataMap) {
        let serialMeta = this.serialMetadataMap[serialKey];
        let currentPointList = [];
        for (let controlPoint of serialMeta.controlPoints) {
          let currentPoint = controlPoint.getCurrentPoint(this.fraction);
          currentPoint.index = serialMeta.converter.convertKey2Index(controlPoint.key());
          currentPoint.key = controlPoint.key();
          currentPoint.label = controlPoint.label();
          currentPointList.push(currentPoint);
        }

        if (currentPointList.length === 0) continue;

        //draw curve
        let curvePoints = [].concat(currentPointList);
        let delta = 100;
        curvePoints.splice(0, 0, new Point(curvePoints[0].x - delta, curvePoints[0].y));
        let prePoint = curvePoints.length > 0 ? curvePoints[curvePoints.length - 1] : null;
        let preprePoint = curvePoints.length > 1 ? curvePoints[curvePoints.length - 2] : null;
        curvePoints.push(new Point(
            curvePoints[curvePoints.length - 1].x + delta,
            preprePoint == null ? prePoint.y : (prePoint.y - preprePoint.y) / (prePoint.x - preprePoint.x) * delta + prePoint.y
        ));
        preprePoint = prePoint;
        prePoint = curvePoints[curvePoints.length - 1];
        curvePoints.push(new Point(
            prePoint.x + delta,
            prePoint.y + prePoint.y - preprePoint.y,
        ));
        let curveControlPoints = [];
        for (let n = 1; n < curvePoints.length - 2; n++) {
          let isPeek1 = this.isPeek(curvePoints[n], curvePoints[n - 1], curvePoints[n + 1]);
          let isPeek2 = this.isPeek(curvePoints[n + 1], curvePoints[n], curvePoints[n + 2]);

          let alpha1 = (1 - (curvePoints[n].x - curvePoints[n - 1].x) / (curvePoints[n + 1].x - curvePoints[n - 1].x)) * .3;
          let alpha2 = (curvePoints[n + 1].x - curvePoints[n].x) / (curvePoints[n + 2].x - curvePoints[n].x) * .3;

          curveControlPoints.push({
            x: curvePoints[n].x,
            y: curvePoints[n].y,
            cp1x: curvePoints[n].x + alpha1 * (curvePoints[n + 1].x - curvePoints[n - 1].x),
            cp1y: isPeek1 ? curvePoints[n].y : curvePoints[n].y + alpha1 * (curvePoints[n + 1].y - curvePoints[n - 1].y),
            cp2x: curvePoints[n + 1].x - alpha2 * (curvePoints[n + 2].x - curvePoints[n].x),
            cp2y: isPeek2 ? curvePoints[n + 1].y : curvePoints[n + 1].y - alpha2 * (curvePoints[n + 2].y - curvePoints[n].y),
          })

        }
        ctx.save();
        ctx.strokeStyle = serialMeta.color;
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(curvePoints[1].x + this.offset, curvePoints[1].y);
        for (let n = 1; n < curveControlPoints.length; n++) {
          let curveControlPoint = curveControlPoints[n - 1];
          let endPoint = curveControlPoints[n];
          ctx.bezierCurveTo(curveControlPoint.cp1x + this.offset, curveControlPoint.cp1y, curveControlPoint.cp2x + this.offset, curveControlPoint.cp2y, endPoint.x + this.offset, endPoint.y);
        }
        ctx.stroke();
        ctx.closePath();
        ctx.restore();

        //draw point
        for (let currentPoint of currentPointList) {
          ctx.beginPath();
          ctx.fillStyle = serialMeta.color;
          ctx.lineWidth = 3;
          ctx.strokeStyle = "white";
          ctx.arc(currentPoint.x + this.offset, currentPoint.y, 7, 0, Math.PI * 2);
          ctx.fill();
          ctx.stroke();
          ctx.closePath();
        }

        //draw bubble
        if (this.serials.length === 1) {
          let hoverPoint = null;
          for (let currentPoint of currentPointList) {
            if (currentPoint.index === this.hoverIndex) {
              hoverPoint = currentPoint;
              break;
            }
          }
          if (!hoverPoint) {
            for (let currentPoint of currentPointList) {
              if (currentPoint.index === this.centerIndex) {
                hoverPoint = currentPoint;
                break;
              }
            }
          }
          if (hoverPoint) {
            this.drawBubble(ctx, new Point(hoverPoint.x + this.offset, hoverPoint.y), hoverPoint.label, serialMeta);
          }
        }
      }

    },

    drawBubble: function (ctx, point, label, serialMeta) {
      if (!label) return;
      ctx.save();
      ctx.strokeStyle = "#dadada";
      ctx.fillStyle = serialMeta.color;
      let defaultYOffset = 16;
      let angleWidth = 5;
      let angleHeight = 5;
      let yOffset = defaultYOffset;
      let textHeight = 16;
      ctx.font = `${textHeight}pt Arial`;
      let textWidth = ctx.measureText(label).width;
      let width = textWidth + 16;
      let height = textHeight + 8;
      if (point.y - height - defaultYOffset > 0) {
        yOffset = -height - defaultYOffset;
      }

      let left = point.x - .5 * width;
      let top = point.y + yOffset;
      let right = left + width;
      let bottom = top + height;
      let radius = Math.min(width, height) * .5;
      ctx.shadowColor = serialMeta.color;
      ctx.shadowBlur = 20;
      ctx.beginPath();
      ctx.moveTo(left + radius, top);
      if (yOffset > 0) {
        ctx.lineTo(point.x - angleWidth, top);
        ctx.lineTo(point.x, top - angleHeight);
        ctx.lineTo(point.x + angleWidth, top);
      } else {
        ctx.lineTo(right - radius, top);
      }

      ctx.arcTo(right, top, right, top + radius, radius);
      ctx.lineTo(right, top + radius);
      ctx.lineTo(right, bottom - radius);
      ctx.arcTo(right, bottom, right - radius, bottom, radius);
      ctx.lineTo(right - radius, bottom);
      if (yOffset < 0) {
        ctx.lineTo(point.x + angleWidth, bottom);
        ctx.lineTo(point.x, bottom + angleHeight);
        ctx.lineTo(point.x - angleWidth, bottom);
      } else {
        ctx.lineTo(left + radius, bottom);
      }

      ctx.arcTo(left, bottom, left, bottom - radius, radius);
      ctx.lineTo(left, bottom - radius);
      ctx.lineTo(left, top + radius);
      ctx.arcTo(left, top, left + radius, top, radius);
      ctx.fill();
      ctx.closePath();

      ctx.restore();

      ctx.textAlign = "center";
      let lightness = this.getLightness(serialMeta.color);
      if (lightness > .7) {
        ctx.fillStyle = "#1d1d1d";
      } else {
        ctx.fillStyle = "white";
      }

      ctx.fillText(label, point.x, point.y + yOffset + height - 7);
    },

    getLightness(color) {
      let red = parseInt(color.substring(1, 3), 16);
      let green = parseInt(color.substring(3, 5), 16);
      let blue = parseInt(color.substring(5, 7), 16);

      return (0.299 * red + 0.587 * green + 0.114 * blue) / 255.0
    },

    drawHistogram: function (ctx) {
      let histogramWidth = 30;
      for (let serialKey in this.serialMetadataMap) {
        ctx.save();
        let serialMeta = this.serialMetadataMap[serialKey];
        ctx.fillStyle = serialMeta.color;
        let currentPointList = [];
        for (let controlPoint of serialMeta.controlPoints) {
          let currentPoint = controlPoint.getCurrentPoint(this.fraction);
          currentPoint.key = controlPoint.key();
          currentPoint.label = controlPoint.label();
          currentPoint.index = serialMeta.converter.convertKey2Index(currentPoint.key);
          currentPointList.push(currentPoint);
        }

        for (let currentPoint of currentPointList) {
          let left = currentPoint.x - .5 * histogramWidth + this.offset;
          let top = currentPoint.y;
          let right = left + histogramWidth;
          let bottom = this.height - this.paddingBottom;

          ctx.beginPath();
          ctx.rect(left, top, right - left, bottom - top);
          ctx.fill();
          ctx.closePath();
        }

        //draw label
        if (this.serials.length === 1) {
          let hoverPoint = null;
          for (let currentPoint of currentPointList) {
            if (currentPoint.index === this.hoverIndex) {
              hoverPoint = currentPoint;
              break;
            }
          }
          if (!hoverPoint) {
            for (let currentPoint of currentPointList) {
              if (currentPoint.index === this.centerIndex) {
                hoverPoint = currentPoint;
                break;
              }
            }
          }
          if (hoverPoint) {
            this.drawHistogramPointLabel(ctx, new Point(hoverPoint.x + this.offset, hoverPoint.y), hoverPoint.label, serialMeta);
          }
        }
        ctx.restore();
      }

    },

    drawHistogramPointLabel: function (ctx, point, label, serialMeta) {
      if (!label) return;
      let fontSize = 12;
      ctx.save();
      ctx.fillStyle = serialMeta.color;
      ctx.font = `${fontSize}pt Arial`;
      ctx.textAlign = "center";
      ctx.textBaseline = "bottom";

      let angleWidth = 5;
      let angleHeight = 4;
      let yOffset = -5;
      ctx.beginPath();
      ctx.moveTo(point.x, point.y + yOffset);
      ctx.lineTo(point.x + angleWidth, point.y + yOffset - angleHeight);
      ctx.lineTo(point.x - angleWidth, point.y + yOffset - angleHeight);
      ctx.fill();
      ctx.closePath();

      ctx.fillText(label, point.x, point.y + yOffset - angleHeight + yOffset);
      ctx.restore();
    },

    drawTip: function (ctx) {
      if (!this.isTipVisible) return;
      let tipIndex = this.tipIndex;
      if (tipIndex == null) return;
      let tipTitle = null;
      let tipItems = [];
      let maxVal = null;
      for (let serialKey in this.serialMetadataMap) {
        let serialMeta = this.serialMetadataMap[serialKey];
        if (!tipTitle) {
          let key = serialMeta.converter.convertIndex2Key(tipIndex);
          if (serialMeta.converter.formatFullLabel) {
            tipTitle = serialMeta.converter.formatFullLabel(key, tipIndex);
          } else {
            tipTitle = serialMeta.converter.formatLabel(key, tipIndex, false);
          }
        }
        let key = serialMeta.converter.convertIndex2Key(tipIndex);
        let controlPoint = serialMeta.getControlPointByKey(key);
        if (!controlPoint) continue;
        let content = controlPoint.value.valLabel;
        let color = serialMeta.color;
        let itemTitle = serialMeta.title;
        tipItems.push({
          title: itemTitle,
          content,
          color,
        });

        if (maxVal == null || maxVal < controlPoint.value.val) {
          maxVal = controlPoint.value.val;
        }
      }
      if (tipItems.length === 0) return;

      let tipFontSize = 15;
      let tipLineHeight = 18;

      ctx.save();
      ctx.font = `${tipFontSize}px Arial`;

      let padding = 4;

      let tipColorDotRadius = 5;

      let tipPanelWidth;
      let tipPanelHeight;

      let columnWidth = ctx.measureText(tipTitle).width;
      for (let tipItem of tipItems) {
        let tip = `${tipItem.title}: ${tipItem.content}`
        let width = ctx.measureText(tip).width + tipColorDotRadius + tipColorDotRadius + padding;
        columnWidth = Math.max(width, columnWidth);
      }

      let maxRows = Math.floor((this.height - padding - padding - tipLineHeight - padding) / tipLineHeight);
      let columns = Math.ceil(tipItems.length / maxRows);

      tipPanelWidth = (columnWidth + padding) * columns + padding + padding;
      if (columns > 1) {
        tipPanelHeight = maxRows * tipLineHeight + tipLineHeight + padding + padding + padding;
      } else {
        tipPanelHeight = tipItems.length * tipLineHeight + tipLineHeight + padding + padding + padding;
      }

      let tipPanelLeft = this.transformIndex2X(tipIndex) + this.offset + 10;
      let tipPanelTop = this.transformVal2Y(maxVal) - tipPanelHeight + 10;
      if (tipPanelLeft + tipPanelWidth + padding > this.width) {
        tipPanelLeft = this.width - tipPanelWidth - padding;
      }
      if (tipPanelTop - padding < 0) {
        tipPanelTop = padding;
      }

      ctx.fillStyle = "#0f4f3f";

      ctx.save();
      ctx.shadowColor = '#bec0c0';
      ctx.shadowBlur = 20;
      ctx.beginPath();
      this.aroundRect(ctx, tipPanelLeft, tipPanelTop, tipPanelWidth, tipPanelHeight, 10);
      ctx.fill();
      ctx.closePath();
      ctx.restore();

      let x = tipPanelLeft + padding;
      let y = tipPanelTop + padding + tipLineHeight;
      ctx.textAlign = "left";
      ctx.textBaseline = "bottom";
      ctx.fillStyle = "#bec0c0";
      ctx.fillText(tipTitle, x, y);
      y += padding;

      let rowIndex = 0;
      let columnIndex = 0;
      for (let tipItem of tipItems) {
        y += tipLineHeight;
        ctx.fillStyle = tipItem.color;
        ctx.beginPath();
        ctx.arc(x + tipColorDotRadius, y - .5 * tipLineHeight, tipColorDotRadius, 0, Math.PI * 2);
        ctx.fill();
        ctx.closePath();

        ctx.fillStyle = "white"
        ctx.fillText(`${tipItem.title ? tipItem.title + ':' : ''}${tipItem.content}`, x + tipColorDotRadius + tipColorDotRadius + padding, y);
        rowIndex++;
        if (rowIndex >= maxRows) {
          columnIndex++;
          rowIndex = 0;
          x = tipPanelLeft + padding + (columnWidth + padding) * columnIndex;
          y = tipPanelTop + padding + tipLineHeight + padding;
        }
      }

      ctx.restore();
    },

    aroundRect(ctx, left, top, width, height, radius = 0) {
      if (radius > .5 * width) radius = .5 * width;
      if (radius > .5 * height) radius = .5 * height;

      let right = left + width;
      let bottom = top + height;

      if (radius <= 0) {
        ctx.rect(left, top, width, height);
      } else {
        ctx.moveTo(left + radius, top);
        if (radius < .5 * width) {
          ctx.lineTo(right - radius, top);
        }
        ctx.arcTo(right, top, right, top + radius, radius);
        if (radius < .5 * height) {
          ctx.lineTo(right, bottom - radius);
        }
        ctx.arcTo(right, bottom, right - radius, bottom, radius);
        if (radius < .5 * width) {
          ctx.lineTo(left + radius, bottom);
        }
        ctx.arcTo(left, bottom, left, bottom - radius, radius);
        if (radius < .5 * height) {
          ctx.lineTo(left, top + radius);
        }
        ctx.arcTo(left, top, left + radius, top, radius);
      }
    }
  },


}
</script>

<style scoped>

.linear-chart {
  width: 100%;
  height: 100%;
}

</style>