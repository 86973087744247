const predefinedIntervals = [.1, .25, .5, 1, 2.5, 5, 10, 25, 50, 100, 250, 500, 1000, 2500, 5000, 10000];
import ExactNumber from "@/assets/javascript/exact-number";

export function getAutoChartGrid(min, max, maxSteps = 5) {
    let interval = null;
    let minIndex = null;
    let maxIndex = null;
    for (let predefinedInterval of predefinedIntervals) {
        minIndex = Math.floor(min / predefinedInterval);
        maxIndex = Math.ceil(max / predefinedInterval);
        if (maxIndex - minIndex + 1 <= maxSteps) {
            interval = predefinedInterval;
            break;
        }
    }

    if (interval == null) {
        throw new Error('can not get auto chart grid');
    }

    let grid = [];
    if (maxIndex === minIndex) maxIndex = minIndex + 2;

    for (let index = minIndex; index <= maxIndex; index++) {
        let val = index * interval;
        let label = ExactNumber.stringify(ExactNumber.of(val * 100, 2));
        grid.push({
            val,
            label,
        });
    }

    return grid;
}

/**
 * 绘图坐标
 */
export class Point {

    /**
     * 构造函数
     * @param x {Number}
     * @param y {Number}
     */
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }

    x = 0;

    y = 0;
}

/**
 * 控制点，用于控制动画的播放
 */
export class ControlPoint {

    /**
     * 构造函数
     * @param index {Number}
     * @param source {Point}
     * @param target {Point}
     */
    constructor(value, source, target) {
        this.value = value;
        this.source = source;
        this.target = target;
    }

    /**
     * 控制点对应的序列值
     * @type {Object}
     */
    value = null;

    /**
     * 动画起始位置
     * @type {Point}
     */
    source = null;

    /**
     * 动画结束位置
     * @type {Point}
     */
    target = null;

    /**
     * 返回对应的key
     * @returns {String}
     */
    key() {
        return this.value.key;
    }

    /**
     * 返回值对应的标签
     * @returns {String}
     */
    label() {
        return this.value.valLabel;
    }

    /**
     * 根据动画的执行阶段，获取当前绘图坐标
     * @param fraction {Number} 0.0-1.0之间
     */
    getCurrentPoint(fraction) {
        let x = (1.0 - fraction) * this.source.x + fraction * this.target.x;
        let y = (1.0 - fraction) * this.source.y + fraction * this.target.y;
        return new Point(x, y);
    }

}

/**
 * 绘图序列的元数据
 */
export class SerialMetadata {

    /**
     * 序列的关键字
     * @type {string}
     */
    key = "key";

    /**
     * 序列的标题
     * @type {string}
     */
    title = "title";

    /**
     * 序列的值列表
     * @type {[Object]}
     */
    values = [];

    /**
     * 各种转换器定义
     * @type {Object}
     */
    converter = {}

    /**
     * 序列控制点
     * @type {[ControlPoint]}
     */
    controlPoints = [];

    /**
     * 序列颜色
     * @type {string}
     */
    color = "#f091a6";

    /**
     * 最小值
     * @type {number}
     */
    minVal = 0;

    /**
     * 最大值
     * @type {number}
     */
    maxVal = 0;

    /**
     * 最小index
     * @type {Number}
     */
    minIndex = 0;

    /**
     * 最大index
     * @type {number}
     */
    maxIndex = 0;

    /**
     * 坐标网格
     * @type {Array}
     */
    grid = [];

    /**
     * 序列元数据
     * @param serial 序列
     * @param oldSerialMetadata 原序列元数据，用于生成新的控制点列表
     */
    constructor(serial) {
        this.key = serial.key;
        this.title = serial.title ? serial.title : "";
        this.values = serial.values;
        this.color = serial.color;
        this.converter = serial.converter;
        this.init();
    }

    init() {
        let minVal = null;
        let maxVal = null;
        let minIndex = null;
        let maxIndex = null;

        let firstNonemptyValue = null;
        for (let value of this.values) {
            if (value.isEmpty) continue;
            firstNonemptyValue = value;
            break;
        }

        if (firstNonemptyValue) {
            minIndex = this.converter.convertKey2Index(firstNonemptyValue.key);
        }

        let lastNonemptyValue = null;
        for (let n = this.values.length - 1; n >= 0; n--) {
            if (this.values[n].isEmpty) continue;
            lastNonemptyValue = this.values[n];
            break;
        }

        if(lastNonemptyValue) {
            maxIndex = this.converter.convertKey2Index(lastNonemptyValue.key);
        }

        for (let value of this.values) {
            if (value.isEmpty) continue;
            if (minVal == null || minVal > value.val) {
                minVal = value.val;
            }
            if (maxVal == null || maxVal < value.val) {
                maxVal = value.val;
            }
        }

        this.minIndex = minIndex;
        this.maxIndex = maxIndex;
        this.minVal = minVal;
        this.maxVal = maxVal;
    }


    /**
     * 根据key获取控制点
     * @param key {String}
     */
    getControlPointByKey(key) {
        for (let controlPoint of this.controlPoints) {
            if (controlPoint.key() === key) return controlPoint;
        }
        return null;
    }

    convertKey2Index(key) {
        if (typeof this.converter.convertKey2Index === 'function') {
            return this.converter.convertKey2Index(key);
        } else {
            return null;
        }
    }

    convertIndex2Key(index) {
        if (typeof this.converter.convertIndex2Key === 'function') {
            return this.converter.convertIndex2Key(index);
        } else {
            return null;
        }
    }

}



