import {TimeUtils} from "@/assets/javascript/time-utils";

const originalDate = TimeUtils.parseDate('1970-01-01');

export class DateChartConverter {

    constructor(skip = 0) {
        this.skip = skip;
    }

    convertKey2Index(key) {
        let date = TimeUtils.parseDate(key);
        return TimeUtils.daysBetween(originalDate, date);
    }

    convertIndex2Key(index) {
        let date = TimeUtils.plusDays(originalDate, index);
        return TimeUtils.format('yyyy-MM-dd', date);
    }

    formatLabel(key, index, skip = true) {
        let skipFlag = true;
        if (this.skip === 0 || !skip) {
            skipFlag = false;
        } else {
            skipFlag = index % (this.skip + 0) != 0;
        }
        if (skipFlag) {
            return null;
        } else {
            return TimeUtils.format('MM月dd日', key);
        }
    }

    formatFullLabel(key, index) {
        return TimeUtils.format('yyyy年MM月dd日', key);
    }
}
