<template>
  <div v-if="user">
    <div class="layout-horizontal layout-middle">
      <user-avatar :user="user"></user-avatar>
      <span class="margin-left font-size-large">{{user.user.name}}</span>
    </div>
    <div class="margin-top">
      <span>{{user.message}}</span>
    </div>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import UserAvatar from "@/components/user/UserAvatar";

export default {
  name: "UserInfo",
  components: {UserAvatar},
  mixins: [httpapi],
  props: {
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {
      user: null,
    }
  },
  computed: {
    inputParams: function () {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler: function () {
        this.loadingCode++;
        this.user = null;
        this.loadUserByOrganization();
      },
      immediate: true,
    }
  },
  methods: {
    loadUserByOrganization: function () {
      if (!this.userId || !this.organizationId) return;
      this.$reqGet({
        path: '/user/get/by_organization',
        data: {
          id: this.userId,
          organizationId: this.organizationId,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let user = res.data;
            this.user = user;
          })
          .catch(() => {
            this.$message.error('加载失败');
          })
    }
  }
}
</script>

<style scoped>

</style>