<template>
  <div
      class="time-line"
      :style="`width: ${width}px;`"
  >
    <div class="title font-size-medium margin-right">
      <span v-if="!onlyLine">
        {{ title }}
      </span>
    </div>
    <div class="line">
      <div
          class="line-pre"
          :class="{
            'active': !isFirst,
          }"
          :style="`background-color: ${!isFirst ? color: 'transparent'}`"
      ></div>
      <div
          class="line-post"
          :class="{
            'active': !isLast,
          }"
          :style="`background-color: ${!isLast ? color: 'transparent'}`"
      ></div>
    </div>
    <div
        v-if="!onlyLine"
        class="dot"
        :style="`background-color: ${color}`"
    ></div>
    <div
        v-if="onlyLine"
        class="dot"
        style="background-color: transparent;"
    ></div>
  </div>
</template>

<script>
export default {
  name: "TimeLine",
  props: {
    width: {
      type: Number,
      default: 118,
    },
    title: {
      type: String,
      default: "time",
    },
    isFirst: Boolean,
    isLast: Boolean,
    color: {
      type: String,
      default: '#f091a6',
    },
    onlyLine: Boolean,
  }
}
</script>

<style scoped>

.time-line {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
}

.title {
  text-align: right;
  flex: auto;
  align-self: center;
}

.time-line .line {
  position: relative;
  left: 6.5px;
}

.time-line .line-pre {
  width: 2px;
  height: 50%;
}

.time-line .line-post {
  width: 2px;
  height: 50%;
}

.time-line .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  align-self: center;
}

</style>